var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-col",
        { attrs: { span: 18 } },
        [
          _c(
            "a-spin",
            { attrs: { tip: "Loading...", spinning: _vm.spinning } },
            [
              _c(
                "div",
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 18 } }, [
                        _c(
                          "p",
                          [
                            _c(
                              "a-divider",
                              { attrs: { orientation: "left" } },
                              [_vm._v("组一")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("a-col", { attrs: { span: 6 } }),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _vm._l(
                            _vm.dataSource[0].fileDetails,
                            function (fileDetail, index) {
                              return _c("div", { key: index }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      width: "104px",
                                      height: "104px",
                                      "margin-right": "10px",
                                      margin: "0 8px 8px 0",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          position: "relative",
                                          padding: "8px",
                                          border: "1px solid #d9d9d9",
                                          "border-radius": "4px",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            src: fileDetail.imgUrl,
                                            preview: _vm.dataSource[0].key,
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 18 } }, [
                        _c(
                          "p",
                          [
                            _c(
                              "a-divider",
                              { attrs: { orientation: "left" } },
                              [_vm._v("组二")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("a-col", { attrs: { span: 6 } }),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _vm._l(
                            _vm.dataSource[1].fileDetails,
                            function (fileDetail, index) {
                              return _c("div", { key: index }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      width: "104px",
                                      height: "104px",
                                      "margin-right": "10px",
                                      margin: "0 8px 8px 0",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          position: "relative",
                                          padding: "8px",
                                          border: "1px solid #d9d9d9",
                                          "border-radius": "4px",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            src: fileDetail.imgUrl,
                                            preview: _vm.dataSource[1].key,
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("p"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }